window.$ = window.jQuery = require('jquery');
require('slick-carousel');
require('ion-rangeslider');

require('./catalog');
require('./pdp');

+function ($) {
    'use strict';

    $('.Header-Hamburger').on('click', () => {
        $('.Header-Menu').toggleClass('Header-Menu__Open');
        $('.Header-Menu-Top').toggleClass('Header-Menu-Top__Open');
        $('.Header-Hamburger').toggleClass('Header-Hamburger__Open');
        if ($('.Header-Menu').is('.Header-Menu__Open')) {
            $('#Menu-Fade-Mask').fadeIn();
        } else {
            $('#Menu-Fade-Mask').fadeOut();
        }
    });
    
    $('#Menu-Fade-Mask').on('click', () => {
        $('.Header-Hamburger').trigger('click');
    });    

    $('.HomePage-Slider').slick({
        speed: 500,
        dots: true,
        fade: false,
        autoplay: true,
        autoplaySpeed: 6000,
        arrows: false
    });

    $('.HomePage-Reviews__Slider').slick({
        speed: 500,
        fade: false,
        slidesToShow: 5,
        infinite: true,
        arrows: false,
        centerMode: true,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });

    $('.FaqPage-Container [data-target]').on('click', ({ currentTarget }) => {
        const target = $(currentTarget).data('target');
        const shouldOpen = !JSON.parse($(currentTarget).attr('aria-expanded'));

        $(currentTarget).attr('aria-expanded', shouldOpen);
        $(target).slideToggle();

        if (!shouldOpen) {
            $(target).find('[aria-expanded="true"]').each((key, child) => {
                const target = $(child).data('target');

                $(target).slideToggle();
            })
        }
    })

    // Accordion
    $('.Accordion-Item').on('click', ({ currentTarget }) => {
        $('.Accordion-Active').removeClass('Accordion-Active');
        $(currentTarget).addClass('Accordion-Active');
    });
}(jQuery);
