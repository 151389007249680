+function ($) {
    if (!$('.plp_path').length) {
        return;
    }

    if ($('.ship-product-item').length) {
        const shipProducts = $('.product-item.ship-product-item');

        $('[data-ship-type-filter]').click(function () {
            const shipType = $(this).data('ship-type-filter');

            if ($(this).hasClass('active')) {
                $('[data-ship-type-filter].active').removeClass('active');
                $(this).removeClass('active');
                shipProducts.show();
            } else {
                $('[data-ship-type-filter].active').removeClass('active');
                $(this).addClass('active');
                shipProducts.hide();
                $('.product-item[data-ship-type="' + shipType + '"]').show();
            }
        });

        $('[data-ship-faction-filter]').click(function () {
            const shipFaction = $(this).data('ship-faction-filter');

            if ($(this).hasClass('active')) {
                $('[data-ship-faction-filter].active').removeClass('active');
                $(this).removeClass('active');
                shipProducts.show();
            } else {
                $('[data-ship-faction-filter].active').removeClass('active');
                $(this).addClass('active');
                shipProducts.hide();
                $('.product-item[data-ship-faction="' + shipFaction + '"]').show();
            }
        });
    }

    if ($('.ProductBlock-Item__Structure').length) {
        const structureProducts = $('.ProductBlock-Item__Structure');

        $('[data-structure-type-filter]').on('click', function () {
            const structureType = $(this).data('structure-type-filter');

            $('.CategoryPage-EmptyMessage').remove();

            if ($(this).hasClass('CategoryPage-StructureFilter__Active')) {
                $('.CategoryPage-StructureFilter__Active').removeClass('CategoryPage-StructureFilter__Active');
                $(this).removeClass('CategoryPage-StructureFilter__Active');
                structureProducts.show();
            } else {
                $('.CategoryPage-StructureFilter__Active').removeClass('CategoryPage-StructureFilter__Active');
                $(this).addClass('CategoryPage-StructureFilter__Active');
                structureProducts.hide();
                const filteredProducts = $('.ProductBlock-Item__Structure[data-structure-type="' + structureType + '"]');

                // todo: for ships
                if (filteredProducts.length) {
                    filteredProducts.show();
                } else {
                    $('<div class="CategoryPage-EmptyMessage">Sorry, no products matching selected filters</div>').prependTo('.CategoryPage-Container');
                }
            }
        });
    }

    // TODO
    $('#look-for-character-form').submit(function (e) {
        e.preventDefault();

        var that = $(this);
        that.find('button').prop('disabled', true).addClass('disabled');

        $.ajax({
            type: 'POST',
            url: that.attr('action'),
            headers: {
                'X-CSRF-TOKEN': window.csrf_token
            },
            data: {
                'name': that.find('#contact-name').val(),
                'email': that.find('#contact-email').val(),
                'message': that.find('#contact-message').val(),
                'request_type': that.find('#request-type').val()
            },
            success: function () {
                console.log(that);
                console.log(that.find('input'));
                that.find('input, textarea').val('').change();
                $('#look-for-character-modal').modal('hide');
                that.find('button').prop('disabled', false).removeClass('disabled');

                new Noty({
                    text: '<strong>Thank you!</strong> We will contact you soon.',
                    type: 'success',

                    timeout: 4000,
                    killer: true
                }).show();
            },
            error: function () {
                new Noty({
                    text: '<strong>Whoops!</strong> Something went wrong. Please try again or ' +
                        '<a class="alert-link" href="/contacts" target="_blank">contact the administrators</a>.',
                    type: 'success',

                    timeout: 4000,
                    killer: true
                }).show();
            },
            done: function () {
                that.find('button').prop('disabled', false).removeClass('disabled');
            }
        });
    });

    // TODO
    $('#sell-your-character-form').submit(function (e) {
        e.preventDefault();

        const that = $(this);
        that.find('button').prop('disabled', true).addClass('disabled');

        $.ajax({
            type: 'POST',
            url: that.attr('action'),
            headers: {
                'X-CSRF-TOKEN': window.csrf_token
            },
            data: {
                'name': that.find('#contact-name').val(),
                'email': that.find('#contact-email').val(),
                'message': that.find('#contact-message').val(),
                'request_type': that.find('#request-type').val()
            },
            success: function () {
                console.log(that);
                console.log(that.find('input'));
                that.find('input, textarea').val('').change();
                $('#sell-your-character-modal').modal('hide');
                that.find('button').prop('disabled', false).removeClass('disabled');

                new Noty({
                    text: '<strong>Thank you!</strong> We will contact you soon.',
                    type: 'success',

                    timeout: 4000,
                    killer: true
                }).show();
            },
            error: function () {
                new Noty({
                    text: '<strong>Whoops!</strong> Something went wrong. Please try again or ' +
                        '<a class="alert-link" href="/contacts" target="_blank">contact the administrators</a>.',
                    type: 'success',

                    timeout: 4000,
                    killer: true
                }).show();
            },
            done: function () {
                that.find('button').prop('disabled', false).removeClass('disabled');
            }
        });
    });
}(jQuery);
