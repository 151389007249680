+function ($) {
    if (!$('.ProductPage').length || !window.product) {
        return;
    }

    const {
        title: productTitle,
        category: productCategory,
        productId,
        minPrice,
        maxPrice,
        priceStep,
        productPrice,
        shipFactions,
        additionalData,
        finalPrice,
        rangeValues,
        type: productType
    } = window.product;

    const shipFactionList = shipFactions && shipFactions.length ? shipFactions : null;

    const movePdpInfoBlocks = () => {
        const productInfoBlocks = $('.ProductPage-AdditionalInfo');

        if (window.innerWidth >= 768) {
            productInfoBlocks.appendTo('.ProductPage-Content__Left');
        } else {
            productInfoBlocks.appendTo('.ProductPage-Content__Right');
        }
    };

    movePdpInfoBlocks();

    $(window).on('resize', () => {
        movePdpInfoBlocks();
    });

    // Delivery methods
    $('[data-delivery-method]').on('click', ({ currentTarget }) => {
        const activeClassName = 'ProductPage-DeliveryMethod__Active';
        const deliveryMethodHiddenInput = $('[name="delivery_method"]');
        const addToCartButton = $('.ProductPage-AddToCart');
        $(`.${ activeClassName }`).removeClass(activeClassName);
        $(currentTarget).toggleClass(activeClassName);

        let value = $(currentTarget).data('deliveryMethod');

        if (!$(currentTarget).hasClass(activeClassName)) {
            value = '';
        }

        $('[name="delivery_method"]').val(value);

        addToCartButton.attr(
            'disabled',
            deliveryMethodHiddenInput.length > 0 && !deliveryMethodHiddenInput.val()
        );
    });
    $('[data-delivery-method]').first().trigger('click');

    if (shipFactionList) {
        $('.ProductPage-Gallery').slick({
            speed: 250,
            dots: false,
            draggable: false,
            fade: false,
            autoplay: false,
            arrows: false
        });

        $('[data-ship-faction]').on('click', ({ currentTarget }) => {
            const activeClassName = 'ProductPage-ShipFaction__Active';
            const price = parseFloat($(currentTarget).data('price')).toFixed(2);
            const key = parseInt($(currentTarget).data('key'));
            $(`.${ activeClassName }`).removeClass(activeClassName);
            $(currentTarget).toggleClass(activeClassName);

            $('.ProductPage-Gallery').slick('slickGoTo', key);

            $('.ProductPage-Price').text(price + ' $');
            window.product.finalPrice = price;

            $('.ProductPage-AddToCart').attr('disabled', !$(currentTarget).hasClass(activeClassName));
        });
        $('[data-ship-faction]').first().trigger('click');
    }

    $('.ProductPage-AddToCart').on('click', (e) => {
        e.preventDefault();

        const requestData = {
            'id': productId
        };

        const shipFaction = $('[data-ship-faction].ProductPage-ShipFaction__Active');

        if (shipFactionList) {
            if (shipFaction.length) {
                requestData.ship_faction_id = shipFaction.data('ship-faction');
            } else {
                alert('Please select ship faction');

                return;
            }
        }

        const deliveryMethodHiddenInput = $('[name="delivery_method"]');

        if (deliveryMethodHiddenInput.length > 0) {
            if (!deliveryMethodHiddenInput.val()) {
                alert('Please select delivery method first!');

                return;
            }

            requestData.delivery_method = deliveryMethodHiddenInput.val();
        }

        const omegaCloneState = $('[name=omega_clone_state]');

        if (omegaCloneState.length) {
            const omegaCloneStateChecked = omegaCloneState.is(':checked') ? 1 : 0;

            if (omegaCloneStateChecked) {
                requestData['omega_clone_amount'] = $('#omega-clone-number-input').val();
            } else {
                requestData.qty = (parseInt($('.ProductPage-QtyRange').val()) / (priceStep !== 0 ? priceStep : 1)).toFixed(0);
            }
        } else {
            requestData.qty = (parseInt($('.ProductPage-QtyRange').val()) / (priceStep !== 0 ? priceStep : 1)).toFixed(0);
        }

        if (priceStep === 0 || minPrice === maxPrice) {
            requestData.qty = 1;
        }

        $('.ProductPage-AddToCart').addClass('Loading');

        $.ajax({
            type: 'POST',
            url: '/cart/add',
            headers: {
                'X-CSRF-TOKEN': window.csrf_token
            },
            data: requestData,
            success: function (response) {
                if (response.error === false) {
                    const cartCount = $('.Header-Minicart__Count');

                    if (!cartCount.length) {
                        $('.Header-Minicart').prepend('<span class="Header-Minicart__Count"></span>');
                    }

                    if (!!window.gtag) {
                        gtag('event', 'add_to_cart', {
                            items: [
                                {
                                    id: requestData.id,
                                    name: productTitle,
                                    category: productCategory,
                                    quantity: requestData.qty,
                                    price: finalPrice
                                }
                            ],
                            list_name: 'PDP'
                        });
                    }

                    new Noty({
                        text: '<strong>Well done!</strong> You successfully added this item in your cart.',
                        type: 'success',
                        timeout: 4000,
                        killer: true
                    }).show();
                } else {
                    // TODO: this is duplicate from ajax.error ab bottom
                    new Noty({
                        text: '<strong>Whoops!</strong> Something went wrong. Please try again or contact the administrators</a>.',
                        type: 'danger',
                        timeout: 4000,
                        killer: true
                    }).show();
                }
            },
            error: () => {
                // TODO: SPECIFIC ERROR MESSAGE
                new Noty({
                    text: '<strong>Whoops!</strong> Something went wrong. Please try again or contact the administrators</a>.',
                    type: 'danger',
                    timeout: 4000,
                    killer: true
                }).show();
            },
            complete: () => {
                $('.ProductPage-AddToCart').removeClass('Loading');
            }
        });
    });

    function prettify(num) {
        let si = [
            { value: 1E9, symbol: 'B' },
            { value: 1E6, symbol: 'M' },
            { value: 1E3, symbol: 'K' }
        ], i;

        for (i = 0; i < si.length; i++) {
            if (num >= si[i].value) {
                return (num / si[i].value).toFixed(2).replace(/\.?0+$/, '') + si[i].symbol;
            }
        }

        return num;
    }

    // Range slider
    const onRangeSliderChange = ({ from, from_value }) => {
        const fromValue = from_value || from;
        let qtyRangePrice = productPrice;

        if (additionalData.mass_price) {
            $.each(additionalData.mass_price, function (key, value) {
                key *= priceStep;

                if (fromValue >= key) {
                    qtyRangePrice = value;

                    return true;
                }

                if (fromValue < key) {
                    return false;
                }
            });
        }

        let price;

        if (qtyRangePrice === productPrice) {
            $('.ProductPage-PriceContainer').removeClass('ProductPage-ShowDiscount');
            price = parseFloat(qtyRangePrice * (fromValue / priceStep)).toFixed(2);
            $('.ProductPage-DiscountDiff').text();
        } else {
            price = parseFloat(productPrice * (fromValue / priceStep)).toFixed(2);
            const specialPrice = parseFloat(qtyRangePrice * (fromValue / priceStep)).toFixed(2);
            $('.ProductPage-PriceContainer').addClass('ProductPage-ShowDiscount');
            $('.ProductPage-Price__Discount').text(specialPrice + ' $');
            $('.ProductPage-DiscountDiff').text('You save: ' + Math.abs((price - specialPrice).toFixed(2)) + ' $');
        }

        window.product.finalPrice = price;
        $('.ProductPage-Price').text(price + ' $');
    }

    if (priceStep !== 0 && minPrice !== maxPrice) {
        const rangeOptions = {
            min: minPrice,
            max: maxPrice,
            step: priceStep,
            grid: false,
            skin: 'round',
            prettify,
            decorate_both: false,
            onChange: onRangeSliderChange,
            onUpdate: onRangeSliderChange
        };

        if (productType === 7) {
            rangeOptions.postfix = ' mil SP';
        } else {
            rangeOptions.prefix = 'Qty: ';
        }

        if (rangeValues) {
            rangeOptions.values = rangeValues
        }

        $('.ProductPage-QtyRange').ionRangeSlider(rangeOptions);
    }

    $('[name="omega_clone_state"]').on('change', ({ target }) => {
        const qtyPreselector = $('.ProductPage-QtyPreselector .Btn');
        $('.ProductPage-QtyRange').data('ionRangeSlider').update({ block: target.checked });

        qtyPreselector.attr('disabled', target.checked);

        if (target.checked) {
            $('.ProductPage-PriceContainer').removeClass('ProductPage-ShowDiscount');
            $('.ProductPage-OmegaClone__Form').css('display', 'flex');

            const price = parseFloat(
                $('.ProductPage-OmegaClone__Input').val() * (productPrice * minPrice / priceStep)
            ).toFixed(2);
            window.product.finalPrice = price;

            $('.ProductPage-Price').text(price + ' $');
        } else {
            $('.ProductPage-OmegaClone__Form').hide();
        }
    });

    $('.ProductPage-OmegaClone__Input').on('keypress', ({ key, target }) => {
        const currentChar = parseInt(key, 10);

        if (!isNaN(currentChar) && currentChar !== 0) {
            if (target.value.length <= 0) {
                return true;
            }

            target.value = currentChar;
        }

        return false;
    }).on('change', ({ target: { value } }) => {
        const price = parseFloat(
            value * (productPrice * minPrice / priceStep)
        ).toFixed(2);
        window.product.finalPrice = price;

        $('.ProductPage-Price').text(price + ' $');
    });

    $('[data-preselected-value]').on('click', ({ target: { dataset } }) => {
        const qtyRange = $('.ProductPage-QtyRange');
        const { preselectedValue } = dataset;
        let fromValue = preselectedValue * priceStep;

        if (qtyRange.length) {
            qtyRange.data('ionRangeSlider').update({
                from: rangeValues ? rangeValues.indexOf(fromValue) : fromValue
            });

            return;
        }

        onRangeSliderChange({ from: fromValue });
    });


    window.relatedProductSlider = $('.ProductPage-RelatedProductBlock').slick({
        speed: 500,
        fade: false,
        slidesToShow: 6,
        centerMode: true,
        infinite: true,
        arrows: false,
        responsive: [
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
}(jQuery);
